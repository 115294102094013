import React, { useState } from "react";
import { observer } from "mobx-react";

// Components
import { MainContainer, Text, Subtitle } from "../../components";
import { Button, Checkbox, Input, message, Modal } from "antd";
import { Upload } from "antd";
import { IoCameraOutline, IoReloadOutline } from "react-icons/io5";

import { imageApi } from "../../api";

import { useRouterStore, useAuthStore } from "../../stores";
import axios from "axios";
import "./index.scss";

const { TextArea, Password } = Input;

const inputStyle = {
  backgroundColor: "transparent",
  borderColor: "white",
  marginTop: "5%",
  borderRadius: "10px",
  color: "white",
};

const inputDivStyle: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "10%",
  width: "100%",
};

const renderChild = ({
  uploadImage,
  form,
  setForm,
  submitForm,
  uploadStatus,
  setOpenTnc,
  openTnc,
}: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "10%",
        width: "80%",
        alignItems: "center",
      }}
    >
      <Subtitle style={{ marginBottom: "10%" }} subtitle="會員表格" />
      {/* submit button */}
      <div style={inputDivStyle}>
        <Text text="名稱" />
        <Input
          style={inputStyle}
          value={form && form["name"] ? form["name"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              name: e.target.value,
            })
          }
        />
      </div>
      <div style={inputDivStyle}>
        <Text text="電話號碼" />
        <Input
          style={inputStyle}
          value={form && form["phone"] ? form["phone"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              phone: e.target.value,
            })
          }
        />
      </div>
      <div style={inputDivStyle}>
        <Text text="電郵地址" />
        <Input
          style={inputStyle}
          value={form && form["email"] ? form["email"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              email: e.target.value,
            })
          }
          autoComplete="off"
          autoCorrect="off"
        />
      </div>
      <div style={inputDivStyle}>
        <Text text="住宅地址" />
        <TextArea
          style={inputStyle}
          autoSize={{ minRows: 2 }}
          value={form && form["address"] ? form["address"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              address: e.target.value,
            })
          }
        />
      </div>

      <div style={inputDivStyle}>
        <Text text="緊急聯絡人電話號碼" />
        <Input
          style={inputStyle}
          value={form && form["e_contact"] ? form["e_contact"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              e_contact: e.target.value,
            })
          }
        />
      </div>
      <div style={inputDivStyle}>
        <Text text="登入密碼" />
        <Password
          style={inputStyle}
          value={form && form["password"] ? form["password"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              password: e.target.value,
            })
          }
          autoComplete="off"
        />
      </div>
      <div style={inputDivStyle}>
        <Text text="再次輸入登入密碼" />
        <Password
          style={inputStyle}
          value={form && form["passwordConfirm"] ? form["passwordConfirm"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              passwordConfirm: e.target.value,
            })
          }
        />
        {form["passwordConfirm"] !== form["password"] && (
          <Text text="請輸入相同密碼" color="red" />
        )}
      </div>

      <div style={inputDivStyle}>
        <Text text="加入近照" />
        <Upload
          accept="image/*"
          customRequest={uploadImage}
          className="image-upload-grid"
          showUploadList={{
            showRemoveIcon: false,
          }}
        >
          <Button
            loading={uploadStatus}
            style={{ ...inputStyle, width: "6em", height: "6em" }}
          >
            <div>
              {uploadStatus ? <IoReloadOutline /> : <IoCameraOutline />}
            </div>
          </Button>
        </Upload>
      </div>

      <div
        style={{
          ...inputDivStyle,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Checkbox
          checked={form["agree"]}
          onChange={() => setForm({ ...form, agree: !form["agree"] })}
        />
        <div
          style={{ display: "flex", flexDirection: "row", marginLeft: "2%" }}
          onClick={() => setOpenTnc(true)}
        >
          <Text text="我同意" />
          <Text style={{ margin: 0 }} color="#ffea76" text="條款及細則" />
        </div>
      </div>
      <Button
        shape="round"
        style={{
          marginTop: "10%",
          backgroundColor: "rgba(74,163,149,0.1)",
          marginBottom: "10%",
          height: "5vh",
          color: "#ffea76",
          fontSize: "1.5em",
          fontWeight: 400,
          width: "100%",
        }}
        onClick={() => submitForm()}
      >
        註冊
      </Button>

      {/* tnc */}
      {/* t & c */}
      <Modal
        wrapClassName="TNC"
        title={null}
        // centered
        width={"95%"}
        style={{
          background: "none",
        }}
        bodyStyle={{
          height: "80vh",
          background: "url('/assets/tncBG.png')",
        }}
        visible={openTnc}
        footer={null}
        onCancel={() => setOpenTnc(false)}
      >
        <div
          className="modal-fullscreen"
          style={{
            height: "90%",
            width: "90%",
            overflowY: "scroll",
            padding: 20,
            position: "absolute",
            color: "white",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontWeight: 800,
              fontSize: 20,
            }}
          >
            條款及細側
          </p>
          <p>
            1. 已購買的Package
            可以申請退回，條件是已使用的堂票將以單張計算（即HK$200）及HK$150手續費。
          </p>
          <p>
            2. 如無法出席已預約的課堂, 請於課堂開始前 8 小時取消該預約,任何 8 4.
            小時內取消的課堂, 系統會自動做 late cancel 扣堂.
          </p>
          <p>3. 請於課堂開始前最少5至10分鐘到達, 以利課程順利進行.</p>
          <p>
            4. 若需要預約或取消課程, 請於公司營業時間辦理通知. 學員僅因健康問題,
            懷孕因素才得以申請凍結或延長套票期限,必須提供註冊醫院的醫療相關證明文件正本.(須於套票有效時提出申請,
            且凍結期間無法使用該套票)(每延期一個月須繳交$150元行政費用)
          </p>
          <p
            style={{
              fontWeight: 800,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            條款及細則
          </p>
          <p>1.套票啟動日期由購買當天開始計算.</p>
          <p>2.如過了期限仍未完成所有課程, 公司將不會為餘下課堂延期.</p>
          <p>
            3.若香港天文台懸掛八號或以上的颱風訊號或是黑色暴雨警報生效,
            請與教練或是公司員工確認課程情況.
          </p>
          <p>
            4.公司保留權利更改上述守則, 如有任何爭議,
            公司保留解釋此條款及細則的最終決定權.
          </p>
          <p>
            5. 會員需遵循教練指引進行訓練，任何於Vibe Fitness Studio
            受傷或死亡，本公司恕不負責.
          </p>
          <p
            style={{
              fontWeight: 800,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            失物招領
          </p>
          <p>
            若學員物品於公司內遺失或遭竊盜, 公司概不負責, 如拾獲任何物品,
            公司將予以保留一星期, 逾期且沒人認領的物品將由公司全權處理.
          </p>
        </div>
      </Modal>
    </div>
  );
};

const RegisterPage = () => {
  const [form, setForm] = useState<any>({
    name: "",
    phone: "",
    email: "",
    password: "",
    passwordConfirm: "",
    address: "",
    e_contact: "",
    agree: false,
    photo: "",
  });

  const { history } = useRouterStore();
  const { register } = useAuthStore();

  const [progress, setProgress] = useState(0);
  const [openTnc, setOpenTnc] = useState(false);

  const [uploadStatus] = useState(false);
  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    fmData.append("photo", file);
    try {
      const { url } = imageApi.upload;
      const result = await axios.post(url, fmData, config);
      const { data } = result.data;
      setForm({
        ...form,
        photo: data,
      });
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
  };
  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  };

  const submitForm = () => {
    if (!form["agree"]) {
      message.error("請先同意條款及細則");
      return false;
    }

    for (const index in form) {
      if (form[index] === "" && index !== "photo") {
        message.error("請輸入全部資料");
        return false;
      }
    }
    const emailRegexp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!emailRegexp.test(form["email"])) {
      message.error("請輸入有效電郵");
      return false;
    }

    if (form["phone"].length !== 8 || !isNumeric(form["e_contact"])) {
      message.error("請檢查電話號碼是8位數字");
      return false;
    }

    if (form["e_contact"].length !== 8 || !isNumeric(form["e_contact"])) {
      message.error("請檢查緊急聯絡人電話號碼是8位數字");
      return false;
    }

    register(form);
  };

  const config = {
    history,
    form,
    setForm,
    submitForm,
    uploadImage,
    uploadStatus,
    progress,
    openTnc,
    setOpenTnc,
  };

  return <MainContainer child={() => renderChild(config)} showMenu={false} />;
};

export default observer(RegisterPage);
