import React from 'react';
import { history } from '../../stores';

// Components
import { Menu } from 'antd';

// Style
import { fonts } from '../../config/style';
const MENU_ITEM_STYLE = { fontSize: fonts.textFontSize, fontFamily: fonts.textFontFamily };

const toPage = (page: string) => {
	let redirection = null;

	switch (page) {
		case 'profile':
			redirection = `/${page}`;
			break;
		case 'logout':
			logout();
			redirection = '/';
			break;
	}

	redirection && history.push(redirection);
};

const logout = () => {};

const settingMenu = (
	<Menu style={{ minWidth: 150 }}>
		<Menu.Item key="0" style={MENU_ITEM_STYLE} onClick={() => toPage('profile')}>
			View Profile
		</Menu.Item>
		{/* <Menu.Item key="0" style={MENU_ITEM_STYLE}>
			Settings
		</Menu.Item> */}
		<Menu.Divider />
		<Menu.Item key="3" style={{ ...MENU_ITEM_STYLE, color: '#EC2639' }} onClick={() => toPage('logout')}>
			Logout
		</Menu.Item>
	</Menu>
);

export default settingMenu;
