import React, { useEffect, useCallback } from 'react';

// Component
import { Table } from 'rsuite';
import { ActionCell } from './actionCell';
import { EditCell } from './editCell';
import { DeleteCell } from './deleteCell';
import { EditPasswordCell } from './editPasswordCell';
import { FormattedCell } from './formattedCell';
import { Search } from '../search';

const { Column, HeaderCell, Pagination } = Table;

interface CustomTableProps {
	dataList?: Array<object>;
	fields: Array<object>;
	allowEdit?: boolean;
	allowDelete?: boolean;
	refPage?: string;
	searchTxt?: string;
}

export const CustomTable = (props: CustomTableProps) => {
	let { dataList, fields, allowEdit = true, allowDelete = true, refPage = null, searchTxt } = props;
	const [ data, setData ] = React.useState(dataList);
	const [ loading, setLoading ] = React.useState(false);
	const [ page, setPage ] = React.useState(1);
	const [ displayLength, setDisplayLength ] = React.useState(10);
	const [ sortType, setSortType ] = React.useState(undefined);
	const [ sortColumn, setSortColumn ] = React.useState(undefined);
	const length = (dataList && dataList.length) || 0;

	const getFilterData = useCallback(
		() => {
			let filterData =
				dataList &&
				dataList.filter((_v, i) => {
					const start = displayLength * (page - 1);
					const end = start + displayLength;
					return i >= start && i < end;
				});
			return setData(filterData);
		},
		[ dataList, displayLength, page ]
	);

	const getSortData = useCallback(
		() => {
			if (sortColumn && sortType) {
				return (
					dataList &&
					dataList.sort((a, b) => {
						let x: any = a[sortColumn!];
						let y: any = b[sortColumn!];
						if (typeof x === 'string' && typeof y === 'string') {
							if (sortType === 'asc') {
								return x.localeCompare(y);
							} else {
								return y.localeCompare(x);
							}
						}
						if (sortType === 'asc') {
							return x - y;
						} else {
							return y - x;
						}
					})
				);
			}
			return dataList;
		},
		[ sortColumn, sortType, dataList ]
	);

	useEffect(
		() => {
			setLoading(true);
			getFilterData();
			getSortData();
			setLoading(false);
		},
		[ getFilterData, getSortData, setLoading ]
	);

	const handleChange = (id: number, key: any, value: any) => {
		const nextData = Object.assign([], data);
		let nextDataVal: any = nextData.find((item: any) => item.id === id);
		if (nextDataVal) {
			nextDataVal[key] = value;
			setData(nextData);
		}
	};

	const handleEditState = (id: number) => {
		const nextData = Object.assign([], data);
		const activeItem: any = nextData.find((item: any) => item.id === id);
		activeItem.status = activeItem.status ? null : 'EDIT';
		setData(nextData);
	};

	const handleDeleteState = (id: number) => {};

	const handleChangePage = (dataKey: number) => {
		setPage(dataKey);
	};

	const handleChangeLength = (dataKey: number) => {
		setPage(1);
		setDisplayLength(dataKey);
	};

	const handleSortColumn = (sortColumn: any, sortType: any) => {
		setLoading(false);

		setTimeout(() => {
			setLoading(false);
			setSortColumn(sortColumn);
			setSortType(sortType);
		}, 500);
	};

	const handleFilter = (value: any, e: any) => {
		e.preventDefault();
		let filterData =
			dataList &&
			dataList.filter((val) => {
				let filterObject = Object.values(val).filter((v: any) => {
					return v && v.toString().toLowerCase().includes(value.toLowerCase());
				}, []);
				if (filterObject && filterObject.length > 0) {
					return val;
				} else {
					return null;
				}
			}, []);
		return setData(filterData);
	};

	return (
		<div>
			{/* Search */}
			<Search placeholder={searchTxt || 'Filter Data'} onSearch={handleFilter} />

			{/* Table */}
			<Table
				height={420}
				data={data}
				rowHeight={58}
				loading={loading}
				sortColumn={sortColumn}
				sortType={sortType}
				onSortColumn={handleSortColumn}
				autoHeight
				affixHeader
				style={{ marginTop: 20 }}
			>
				{fields.map((field: any, i: number) => {
					let { name, editable, dataKey, width, fixedCell, caldiff } = field;
					return (
						<Column key={i} width={width || 200} resizable sortable fixed={fixedCell ? 'right' : undefined}>
							<HeaderCell>{name}</HeaderCell>
							{editable ? (
								<EditCell dataKey={dataKey} onChange={handleChange} />
							) : (
								<FormattedCell dataKey={dataKey} caldiff={caldiff} />
							)}
						</Column>
					);
				})}

				{allowEdit ? (
					<Column fixed="right" width={30}>
						<HeaderCell />
						<ActionCell dataKey="id" onClick={handleEditState} />
					</Column>
				) : null}

				{allowDelete ? (
					<Column fixed="right" width={30}>
						<HeaderCell />
						<DeleteCell dataKey="id" onClick={handleDeleteState} />
					</Column>
				) : null}

				{refPage ? (
					<Column fixed="right" width={30}>
						<HeaderCell />
						<EditPasswordCell dataKey="id" onClick={handleDeleteState} />
					</Column>
				) : null}
			</Table>

			{/* Pagination */}
			<Pagination
				lengthMenu={[
					{
						value: 10,
						label: 10
					},
					{
						value: 25,
						label: 25
					},
					{
						value: 50,
						label: 50
					},
					{
						value: 100,
						label: 100
					}
				]}
				activePage={page}
				displayLength={displayLength}
				total={length}
				onChangePage={handleChangePage}
				onChangeLength={handleChangeLength}
			/>
		</div>
	);
};
