import { callApi, classApi, branchApi } from "../api";
import { action, observable } from "mobx";
import { message } from "antd";
import { history } from "./index";

export class ClassStore {
  @observable classList: object[] = [];
  @observable branchList: object[] = [];
  @observable classHistoryList: object[] = [];
  @observable classHistoryListById: object[] = [];
  @observable loading: boolean = true;

  @action.bound
  async getClassList() {
    try {
      const { url, method } = classApi.list;
      // get call api method
      const result: any = await callApi(method, url);
      if (result && result.data) {
        this.classList = result.data;
      }
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }

  @action.bound
  async getBranchList() {
    try {
      const { url, method } = branchApi.list;
      // get call api method
      const result: any = await callApi(method, url);
      if (result && result.data) {
        this.branchList = result.data;
      }
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }

  @action.bound
  async getClassHistoryList() {
    try {
      const { url, method } = classApi.getClassHistory;
      // get call api method
      this.loading = true;
      this.classHistoryList = [];
      const id = localStorage.getItem("id") || "";

      const result: any = await callApi(method, url.replace("{ID}", id));
      if (result && result.data) {
        this.classHistoryList = result.data;
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;

      message.error(error && error.message ? error.message : "Fail to login");
    }
  }

  @action.bound
  async getClassHistoryListById(id: string) {
    try {
      const { url, method } = classApi.getClassHistoryById;
      // get call api method
      const result: any = await callApi(method, url.replace("{ID}", id));
      if (result && result.data) {
        this.classHistoryListById = result.data;
      }
      this.loading = false;
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }

  @action.bound
  async makeBooking(params: any) {
    try {
      const { url, method } = classApi.booking;
      // get call api method
      const id = localStorage.getItem("id") || "";
      await callApi(method, url, {
        ...params,
        memberIds: [id],
      });

      message.success("課堂預約成功!");
      history.push("dashboard");
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }

  @action.bound
  async handleDeleteBooking(params: any) {
    try {
      const { url, method } = classApi.cancelBooking;
      // get call api method
      const id = localStorage.getItem("id") || "";
      await callApi(method, url, {
        ...params,
        memberId: id,
      });

      this.getClassHistoryList();

      message.success("取消課堂預約成功!");
      history.push("dashboard");
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }
}

export const STORE_CLASS = "classStore";
