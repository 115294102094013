import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import MainPage from "./pages/main-page";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import DashboardPage from "./pages/dashboard";
import PurchasePage from "./pages/purchase";
import BookingPage from "./pages/booking";
import HistoryPage from "./pages/history";

import { getLocalStorageWithAsync } from "./utils";

interface PrivateRoutInterface {
  path: string;
  component: () => JSX.Element;
  exact?: boolean;
}

function PrivateRoute(props: PrivateRoutInterface) {
  const { path, component: Component, exact = false } = props;

  let isAuth = getLocalStorageWithAsync("logged");
  isAuth = isAuth && isAuth === "yes" ? true : false;
  const loginPages = ["/", "/register", "/login"];

  if (loginPages.includes(path)) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(routProps): JSX.Element =>
          !isAuth ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: routProps.location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(routProps): JSX.Element =>
        isAuth || path === "/" ? (
          <Component />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: routProps.location } }}
          />
        )
      }
    />
  );
}

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute path={"/"} exact component={MainPage} />
        <PrivateRoute path={"/login"} exact component={LoginPage} />
        <PrivateRoute path={"/register"} exact component={RegisterPage} />
        <PrivateRoute path={"/dashboard"} exact component={DashboardPage} />
        <PrivateRoute path={"/purchase"} exact component={PurchasePage} />
        <PrivateRoute path={"/booking"} exact component={BookingPage} />
        <PrivateRoute path={"/history"} exact component={HistoryPage} />

        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
