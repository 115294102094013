import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

export const BottomModal = (props: any) => {
  const { open, setOpen, children } = props;

  const dismiss = () => {
    setOpen(false);
  };

  return (
    <BottomSheet open={open} onDismiss={() => dismiss()}>
      {children}
    </BottomSheet>
  );
};
