import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
// Components
import { MainContainer, Subtitle, Text } from "../../components";
import { DatePicker } from "antd";
import { useRouterStore, useClassStore } from "../../stores";

const renderChild = ({ filterClassList, setSelectedDate }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "5%",
        width: "95%",
      }}
    >
      <Subtitle subtitle="已完成的訓練" />

      <DatePicker
        defaultValue={moment()}
        picker="year"
        style={{ width: "100%", marginTop: "5%", textAlign: "center" }}
        onChange={(date, dateString) => setSelectedDate(date)}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: "5%",
          alignItems: "baseline",
        }}
      >
        <Text style={{ flex: 1 }} text="Branch" />
        <Text style={{ flex: 1 }} text="Date" />
        <Text style={{ flex: 1 }} text="Time" />
        <Text style={{ flex: 1 }} text="Class" />
      </div>

      {filterClassList &&
        filterClassList.length > 0 &&
        filterClassList.map((item: any) => {
          const { startTime, endTime, className, branchName, courseDate } =
            item || {};

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "2%",
                alignItems: "baseline",
              }}
            >
              <Text style={{ flex: 1 }} text={branchName} />
              <Text
                style={{ flex: 1 }}
                text={moment(courseDate).format("DD, MMMM YY")}
              />

              <Text style={{ flex: 1 }} text={`${startTime} - ${endTime}`} />
              <Text style={{ flex: 1 }} text={className} />
            </div>
          );
        })}
    </div>
  );
};

const HistoryPage = () => {
  const { history } = useRouterStore();
  const { classHistoryListById, getClassHistoryListById } = useClassStore();

  const [filterClassList, setFilterClassList] = useState<any>([]);
  const [currentDate, setCurrentDay] = useState(new Date());

  const filterCourse = (date: Date, histories: any) => {
    const year = moment(date).format("yyyy");

    return histories.filter(
      (item: any) => year === moment(item.courseDate).format("yyyy")
    );
  };

  useEffect(() => {
    setFilterClassList(filterCourse(currentDate, classHistoryListById));
  }, [classHistoryListById, currentDate]);

  const memberId = localStorage.getItem("id");

  useEffect(() => {
    getClassHistoryListById(memberId || "");
  }, [memberId, getClassHistoryListById]);

  const setSelectedDate = (date: any, dateString: any) => {
    setFilterClassList(filterCourse(date, classHistoryListById));
    setCurrentDay(date);
  };

  const config = {
    history,
    filterClassList,
    setSelectedDate,
    currentDate,
  };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(HistoryPage);
