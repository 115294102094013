import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

// Components
import { MainContainer, Subtitle, Text } from "../../components";
import { Button, Modal, Empty } from "antd";
import { useRouterStore, useMemberStore, useClassStore } from "../../stores";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const buttonStyle = {
  borderRadius: "5px",
  marginTop: "5%",
  backgroundColor: "rgba(74,163,149,0.1)",
  height: "5vh",
  color: "#ffea76",
  fontSize: "1.5em",
  fontWeight: 400,
  width: "90%",
  padding: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const renderChild = ({
  history,
  member,
  courseHis,
  handleDelete,
  loading,
}: any) => {
  return (
    <div style={{ width: "100%", paddingBottom: 10 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10%",
        }}
      >
        <Subtitle subtitle={"Hi " + member.name} />
        <img
          style={{
            width: "100%",
            marginTop: "5%",
          }}
          src="/assets/indexBg.png"
          alt="logo"
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10%",
        }}
      >
        <Subtitle style={{ color: "#ffea76" }} subtitle={"已預約的訓練"} />
        {loading && (
          <LoadingOutlined
            style={{ marginTop: 20, fontSize: 24, color: "black" }}
          />
        )}
        {courseHis &&
          courseHis.length > 0 &&
          courseHis.map((his: any) => {
            const { courseDate, endTime, startTime, branchName, className } =
              his;

            const time = startTime.split(":");
            const comparedDateTime = moment(courseDate)
              .set("hour", time[0])
              .set("minute", time[1]);
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "90%",
                  alignItems: "baseline",
                  marginTop: "2%",
                }}
              >
                <Text style={{ flex: 0.5 }} text={branchName} />
                <Text
                  style={{ flex: 1 }}
                  text={moment(courseDate).format("DD-MM-YYYY")}
                />
                <Text style={{ flex: 1 }} text={`${startTime} - ${endTime}`} />
                <Text style={{ flex: 1 }} text={className} />

                {moment(comparedDateTime).diff(new Date(), "hours") > 6 && (
                  <div style={{ flex: 0.5 }} onClick={() => handleDelete(his)}>
                    <img alt="delete" width={"80%"} src="/assets/delete.png" />
                  </div>
                )}
              </div>
            );
          })}

        {(!courseHis || (courseHis && courseHis.length === 0)) && (
          <Empty style={{ marginTop: 20, color: "rgb(255, 234, 118)" }} />
        )}

        <Subtitle
          style={{ color: "#ffea76", marginTop: "5%" }}
          subtitle={"未使用的堂票"}
        />

        <Subtitle subtitle={(member.currentTickets || 0) + " 張"} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button style={buttonStyle} onClick={() => history.push("booking")}>
          預約新的訓練
        </Button>
        <Button style={buttonStyle} onClick={() => history.push("history")}>
          已完成的訓練
        </Button>
        <Button style={buttonStyle} onClick={() => history.push("purchase")}>
          購買堂票
        </Button>
      </div>
    </div>
  );
};

const DashboardPage = () => {
  const { history } = useRouterStore();
  const { member, getMember } = useMemberStore();

  const {
    getClassHistoryList,
    classHistoryList,
    handleDeleteBooking,
    loading,
  } = useClassStore();
  const [courseHis, setCourseHis] = useState<any>([]);

  useEffect(() => {
    setCourseHis(classHistoryList);
  }, [classHistoryList, member]);

  useEffect(() => {
    getMember();
    getClassHistoryList();
  }, [getMember, getClassHistoryList]);

  const handleDelete = (params: any) => {
    const { className, courseDate, startTime, endTime } = params;
    Modal.confirm({
      title: "確定取消以下預約?",
      content: `${className}堂於 ${moment(courseDate).format(
        "DD-MM-YYYY"
      )} (${startTime} - ${endTime})`,
      okText: "確定",
      cancelText: "取消",
      bodyStyle: {
        backgroundColor: "white",
      },
      onOk: async () => {
        await handleDeleteBooking(params);

        Modal.destroyAll();
        getMember();

        return true;
      },
    });
  };

  const config = { history, member, courseHis, handleDelete, loading };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DashboardPage);
