import React, { useState } from "react";
import { observer } from "mobx-react";

// Components
import { MainContainer, Text, Subtitle } from "../../components";
import { Button, Input, message } from "antd";
import { useRouterStore, useAuthStore } from "../../stores";

const { Password } = Input;

const inputStyle = {
  backgroundColor: "transparent",
  borderColor: "white",
  marginTop: "5%",
  borderRadius: "10px",
  color: "white",
};

const inputDivStyle: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "10%",
  width: "100%",
};

const renderChild = ({ history, form, setForm, submitForm }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "10%",
        width: "80%",
        alignItems: "center",
      }}
    >
      <Subtitle style={{ marginBottom: "10%" }} subtitle="登入" />
      {/* submit button */}

      <div style={inputDivStyle}>
        <Text text="電話號碼" />
        <Input
          style={inputStyle}
          value={form && form["phone"] ? form["phone"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              phone: e.target.value,
            })
          }
        />
      </div>
      <div style={inputDivStyle}>
        <Text text="登入密碼" />
        <Password
          style={inputStyle}
          value={form && form["password"] ? form["password"] : ""}
          onChange={(e) =>
            setForm({
              ...form,
              password: e.target.value,
            })
          }
          autoComplete="off"
        />
      </div>

      <Button
        shape="round"
        style={{
          marginTop: "10%",
          backgroundColor: "rgba(74,163,149,0.1)",
          marginBottom: "10%",
          height: "5vh",
          color: "#ffea76",
          fontSize: "1.5em",
          fontWeight: 400,
          width: "100%",
        }}
        onClick={() => submitForm()}
      >
        登入
      </Button>
    </div>
  );
};

const WelcomePage = () => {
  const [form, setForm] = useState<any>({
    phone: "",
    password: "",
  });

  const { history } = useRouterStore();
  const { login } = useAuthStore();

  const submitForm = () => {
    for (const index in form) {
      if (form[index] === "") {
        message.error("請輸入全部資料");
        return false;
      }
    }

    login(form);
  };

  const config = { history, form, setForm, submitForm };

  return <MainContainer child={() => renderChild(config)} showMenu={false} />;
};

export default observer(WelcomePage);
