import { callApi, memberApi } from "../api";
import { action, observable } from "mobx";
import { message } from "antd";
export class MemberStore {
  @observable member: any = {};

  @action.bound
  async getMember() {
    try {
      const { url, method } = memberApi.member;
      // get call api method
      const id = localStorage.getItem("id") || "";
      const result: any = await callApi(method, url.replace("{ID}", id));
      if (result && result.data) {
        this.member = result.data;
      }
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }
}

export const STORE_MEMBER = "memberStore";
