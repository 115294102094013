import React from "react";

// Style
import { colors, fonts } from "../../config/style";

interface TextProps {
  text: string;
  color?: string;
  style?: object;
}

export const Text = (props: TextProps) => {
  let { text, color, style } = props;
  return (
    <p
      style={{
        marginBottom: 0,
        color: color || colors.text,
        fontSize: "1em",
        fontFamily: fonts.textFontFamily,
        fontWeight: 500,
        ...style,
      }}
    >
      {text}
    </p>
  );
};
