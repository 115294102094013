import { AuthStore } from "../stores/auth";
import { getLocalStorage } from "../utils";
import axios, { Method } from "axios";
import { message } from "antd";

require("dotenv").config();
// const { REACT_APP_CLIENT_API_ENDPOINT } = process.env;

const endpoint = "https://www.jcdinteractiveads.com/api3";
// const endpoint = "http://127.0.0.1:8001/api3";
const POST: Method = "POST";
const PUT: Method = "PUT";
const GET: Method = "GET";
const DELETE: Method = "DELETE";

const authApi = {
  login: {
    url: `${endpoint}/login`,
    method: POST,
  },

  register: {
    url: `${endpoint}/member`,
    method: PUT,
  },
};

const imageApi = {
  upload: {
    method: POST,
    url: `${endpoint}/upload`,
  },
};

const memberApi = {
  member: {
    method: GET,
    url: `${endpoint}/member/{ID}`,
  },
};

const classApi = {
  list: {
    method: GET,
    url: `${endpoint}/classSlot/member`,
  },
  booking: {
    method: POST,
    url: `${endpoint}/course/history`,
  },
  cancelBooking: {
    method: DELETE,
    url: `${endpoint}/course/history`,
  },
  getClassHistory: {
    method: GET,
    url: `${endpoint}/course/pending-take-course/{ID}`,
  },
  getClassHistoryById: {
    method: GET,
    url: `${endpoint}/course/history/member/{ID}`,
  },
};

const branchApi = {
  list: {
    method: GET,
    url: `${endpoint}/branch`,
  },
};

const callApi = (
  method: Method,
  url: string,
  params: any = null,
  header = {}
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getLocalStorage("token");
      axios({
        method: method,
        url: url,
        data: params,
        headers: {
          ...header,
          authentication: token || null,
        },
      })
        .then((response) => {
          resolve(response ? response.data : null);
        })
        .catch(async (error) => {
          const { status, data } = error.response || {};
          if (status === 401) {
            message.error("Please login again");
            const { logout } = new AuthStore();
            await logout();
            return false;
          }
          reject(data);
        });
    } catch (e) {
      reject({ code: 500, message: "Please try again later", data: null });
    }
  });
};

export { callApi, authApi, imageApi, memberApi, classApi, branchApi };
