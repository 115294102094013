import React from "react";
import { observer } from "mobx-react";

// Components
import { MainContainer } from "../../components";
import { Button } from "antd";
import { useRouterStore } from "../../stores";

const renderChild = ({ history }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "30%",
        width: "80%",
      }}
    >
      <Button
        shape="round"
        style={{
          backgroundColor: "#ffea76",
          marginBottom: "10%",
          height: "9vh",
          color: "#4aa395",
          fontSize: "3em",
          fontWeight: 800,
        }}
        onClick={() => history.push("login")}
      >
        登入
      </Button>
      <Button
        shape="round"
        style={{
          backgroundColor: "#ffea76",
          marginBottom: "10%",
          height: "9vh",
          color: "#4aa395",
          fontSize: "3em",
          fontWeight: 800,
        }}
        onClick={() => history.push("register")}
      >
        註冊
      </Button>
    </div>
  );
};

const WelcomePage = () => {
  const { history } = useRouterStore();
  const config = { history };

  return <MainContainer child={() => renderChild(config)} showMenu={false} />;
};

export default observer(WelcomePage);
