import React from 'react';

// Components
import { LeftIntro } from '../../components';
import { logo } from '../../config/style';

// Assets
const LOGO = `assets/${logo}`;

interface LoginContainerProps {
	child?: any;
}

export const LoginContainer = (props: LoginContainerProps) => {
	const { child } = props;
	return (
		<div style={{ display: 'flex', flexDirection: 'row', minHeight: 'inherit', flex: 1 }}>
			{/* Left Introduction Block */}
			<div>
				<LeftIntro
					title={`TheyCheck 　　 Candidate Portal`}
					text={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus fames aliquet ac egestas. Felis quis nisl sit nibh praesent scelerisque pellentesque dictum aliquam. Viverra est nulla pretium morbi aenean molestie. Arcu purus, nam a in in semper sit.`}
				/>
			</div>

			<div style={{ padding: 50, flex: 1, flexDirection: 'column', display: 'flex' }}>
				{/* Logo */}
				<div style={{ minHeight: 100 }}>
					<img src={LOGO} alt={'logo'} width={250} />
				</div>

				{/* Child Props */}
				{child ? child() : null}
			</div>
		</div>
	);
};
