import React, { useEffect } from 'react';

// Type
import { iForm } from './types';
import { ButtonHtmlType } from '../button/types';

// Style
import { Form as AntdForm, Input, DatePicker, Select } from 'antd';
import { Button } from '../';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import './style.scss';

// Utils
import { toCamelCase } from '../../utils/transformText';

const { Option } = Select;
interface FormProps {
	fields: Array<iForm>;
	formName: string;
	formStyle?: object;
	onFinish?: any;
	onFinishFailed?: any;
	onFieldsChange?: any;
	submitBtnName?: string;
	submitBtnType?: ButtonHtmlType;
	submitBtnClass?: string;
	initialValues?: object;
	dependsValue?: any;
}

export const Form = (props: FormProps) => {
	let {
		fields: itemFields,
		formName,
		formStyle,
		onFinish,
		onFinishFailed,
		onFieldsChange,
		submitBtnName,
		submitBtnType,
		submitBtnClass,
		initialValues,
		dependsValue = {}
	} = props;

	const [ form ] = AntdForm.useForm();

	useEffect(
		() => {
			form.resetFields();
		},
		[ itemFields, initialValues, form ]
	);

	const renderPrefix = (type: string) => {
		switch (type) {
			case 'username':
				return <UserOutlined className="site-form-item-icon" />;
			case 'password':
			case 'confirmPassword':
				return <LockOutlined className="site-form-item-icon" />;
			case 'token':
				return <LoginOutlined className="site-form-item-icon" />;
			default:
				return null;
		}
	};

	const renderInputWithType = (field: iForm, fieldName: string) => {
		const { type, selectDefault, selectOptions, depends } = field;
		switch (type) {
			case 'text':
			case 'password':
				return (
					<Input
						placeholder={field.name}
						type={field.type}
						style={{ fontSize: 12 }}
						prefix={renderPrefix(fieldName)}
					/>
				);
			case 'dob':
				return <DatePicker placeholder={field.name} style={{ width: '100%' }} />;
			case 'subSelect':
				return (
					<Select>
						{depends &&
							dependsValue[depends] &&
							dependsValue[depends].map((select: any) => (
								<Option key={select.value} value={select.value}>
									{select.name}
								</Option>
							))}
					</Select>
				);

			case 'select':
				return (
					<Select value={selectDefault}>
						{selectOptions &&
							selectOptions.map((select: any) => (
								<Option key={select.value} value={select.value}>
									{select.name}
								</Option>
							))}
					</Select>
				);
			case 'select_multiple':
				return (
					<Select mode="tags" placeholder={`Please select your ${fieldName}`}>
						{selectOptions &&
							selectOptions.map((select: any) => (
								<Option key={select.value} value={select.value}>
									{select.name}
								</Option>
							))}
					</Select>
				);
		}
	};

	return (
		<div>
			<AntdForm
				form={form}
				name={formName}
				style={formStyle || {}}
				onFinish={onFinish || undefined}
				onFinishFailed={onFinishFailed || undefined}
				onFieldsChange={onFieldsChange || undefined}
				initialValues={initialValues || undefined}
			>
				{/* Fields */}
				{itemFields.map((field: any) => {
					const size = field.size || '100%';
					const formStyle = size && { display: 'inline-block', width: size, paddingRight: 40 };
					let { name } = field;
					let fieldName = toCamelCase(name.toLowerCase());

					return (
						<AntdForm.Item
							name={fieldName}
							rules={[ field.rules || { required: false } ]}
							key={field.id}
							hasFeedback
							style={{ ...formStyle }}
							label={name}
						>
							{renderInputWithType(field, fieldName)}
						</AntdForm.Item>
					);
				}, [])}

				{/* Complete */}
				{submitBtnName && (
					<AntdForm.Item>
						<Button buttonName={submitBtnName} htmlType={submitBtnType} className={submitBtnClass} />
					</AntdForm.Item>
				)}
			</AntdForm>
		</div>
	);
};
