// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { STORE_AUTH, AuthStore } from "./auth";
import { STORE_MEMBER, MemberStore } from "./member";
import { STORE_CLASS, ClassStore } from "./class";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_MEMBER]: new MemberStore(),
    [STORE_CLASS]: new ClassStore(),
  };
}

const stores = createStores();

const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

function useMemberStore() {
  const { memberStore } = useStores();
  return memberStore;
}

function useClassStore() {
  const { classStore } = useStores();
  return classStore;
}

export {
  stores,
  history,
  StoresContext,
  useRouterStore,
  useAuthStore,
  useMemberStore,
  useClassStore,
};
