import React from "react";

// Style
import { colors, fonts } from "../../config/style";

interface SubtitleProps {
  subtitle: string;
  color?: string;
  style?: object;
  preset?: string;
  weight?: number;
  size?: number;
}

export const Subtitle = (props: SubtitleProps) => {
  let { subtitle, color, style, preset, size } = props;
  return (
    <h2
      style={{
        marginBottom: 0,
        color: color || colors.text,
        fontSize:
          size || (preset === "page-subtitle" ? 16 : fonts.subtitleFontSize),
        fontFamily: fonts.subtitleFontFamily,
        fontWeight: 800,
        lineHeight: "26px",
        ...style,
      }}
    >
      {subtitle}
    </h2>
  );
};
