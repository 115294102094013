import React from "react";
import { observer } from "mobx-react";

// Components
import { MainContainer, Text, Subtitle } from "../../components";
import { useRouterStore } from "../../stores";

const renderChild = ({ history }: any) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "5%",
      }}
    >
      <Subtitle style={{ marginTop: "10%" }} subtitle={"購買堂票"} />

      <img
        style={{ width: "100%" }}
        src="/assets/purchase1.png"
        alt="purchase class"
      />
      <Text
        style={{
          width: "70%",
          textAlign: "center",
          fontSize: "1em",
        }}
        text={"只限首次會員"}
      />

      <img
        style={{ width: "100%", marginTop: "1%" }}
        src="/assets/purchase2.png"
        alt="purchase class"
      />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10%",
        }}
      >
        <Text
          style={{
            width: "70%",
            textAlign: "center",
            fontSize: "1em",
            marginBottom: "1%",
          }}
          text={"所有PACKAGE 均可多人使用，請在購買是提出"}
        />

        <Subtitle style={{ color: "#ffea76" }} subtitle={"購買方法"} />

        <Text
          style={{
            width: "70%",
            marginTop: "2%",
            textAlign: "center",
            fontSize: "1em",
          }}
          text={"可以經PAYME 或轉數快付款將付款截圖WHATSAPP 傳送至 6120 2796"}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10%",
          }}
          onClick={() => window.open("https://payme.hsbc/weedz", "_blank")}
        >
          <img style={{ width: "15%" }} src="/assets/payme.png" alt="payme" />
          <Subtitle
            style={{ color: "#ffea76", marginLeft: "5%", fontSize: "2em" }}
            subtitle="6120 2796"
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <img style={{ width: "15%" }} src="/assets/fps.png" alt="fps" />
          <Subtitle
            style={{ color: "#ffea76", marginLeft: "5%", fontSize: "2em" }}
            subtitle="6120 2796"
          />
        </div>
      </div>

      <img
        style={{ width: "100%", marginTop: "10%" }}
        src="/assets/whatsapp.png"
        alt="whatsapp"
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send/?phone=68092630&text=%E4%BD%A0%E5%A5%BD%EF%BC%81%E6%88%91%E6%9C%89%E5%98%A2%E5%95%8F%EF%BC%81&type=phone_number&app_absent=0",
            "_blank"
          )
        }
      />
      <Subtitle
        style={{ color: "#ffea76", fontSize: "1.2em", marginTop: "1%" }}
        subtitle="現金付款請預約親臨本中心"
      />
    </div>
  );
};

const PurchasePage = () => {
  const { history } = useRouterStore();

  const config = { history };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(PurchasePage);
