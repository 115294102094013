import React, { useState } from "react";
import { Drawer } from "antd";
import { Subtitle } from "../index";
import { history, useAuthStore } from "../../stores";
import CloseImg from "./close.png";
interface MainContainerProps {
  child?: any;
  styles?: object;
  showMenu?: boolean;
}

const pages = [
  {
    page: "dashboard",
    name: "主頁",
  },
  {
    page: "booking",
    name: "預約上課",
  },
  {
    page: "purchase",
    name: "購買堂票",
  },
  {
    page: "history",
    name: "上堂紀錄",
  },
];

export const MainContainer = (props: MainContainerProps) => {
  const { child, showMenu = true } = props;
  const [visible, setVisible] = useState(false);
  const { logout } = useAuthStore();

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        // backgroundColor: "#4aa395",
        backgroundColor: "#db5c68",
        width: "100%",
        backgroundImage: `url(${CloseImg})`,
        backgroundSize: "contain",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        //         background-repeat:no-repeat;
        // background-position: center center;
      }}
    >
      {/* <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ flex: 1 }} />
        <img
          style={{
            width: "45%",
            marginTop: "15%",
            flex: 1,
          }}
          src="/assets/logo.png"
          alt="logo"
        />
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          {showMenu && (
            <img
              onClick={() => setVisible(true)}
              style={{
                marginLeft: "20%",
                marginTop: "20%",
              }}
              width={"35%"}
              height={"15%"}
              src="/assets/menu.png"
              alt="menu"
            />
          )}
        </div>
      </div>

      <Drawer
        width={"80%"}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "50%",
          }}
        >
          {pages.map((item) => {
            const { page, name } = item;

            return (
              <div
                style={{ marginTop: "10%" }}
                onClick={() => history.push(page)}
              >
                <Subtitle size={22} color="rgb(74, 163, 149)" subtitle={name} />
              </div>
            );
          })}
          <div
            style={{ position: "absolute", bottom: "5%" }}
            onClick={() => logout()}
          >
            <Subtitle size={22} color="rgb(255, 234, 118)" subtitle="登出" />
          </div>
        </div>
      </Drawer> */}

      {/* {child()} */}
    </div>
  );
};
