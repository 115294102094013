import React from 'react';

// Components
import { Subtitle } from '../../components';
import { Avatar, Dropdown } from 'antd';
import settingMenu from './settingMenu';
import { logo } from '../../config/style';

// Assets
const LOGO = `assets/${logo}`;

interface HeaderProps {}

export const Header = (props: HeaderProps) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				padding: '15px 30px',
				alignItems: 'center',
				boxShadow: '4px 1px 4px #dfdfdf'
			}}
		>
			{/* Logo */}
			<div>
				{/* <a> */}
				<img src={LOGO} width={100} alt={'header-logo'} />
				{/* </a> */}
			</div>

			{/* Title */}
			<div>
				<Subtitle style={{ paddingLeft: 50 }} subtitle="" />
			</div>

			{/* Avatar */}
			<div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
				<Dropdown overlay={settingMenu} trigger={[ 'click' ]}>
					<Avatar
						style={{ backgroundColor: 'rgb(110,204,219)', verticalAlign: 'middle', cursor: 'pointer' }}
						size="default"
					>
						L
					</Avatar>
				</Dropdown>
			</div>
		</div>
	);
};
