import { callApi, authApi } from "../api";
import { action, observable } from "mobx";
import { setLocalStorage, removeAllLocalStorage } from "../utils";
import { history } from "./router";
import { message } from "antd";
export class AuthStore {
  @observable twoFaQrcode: string = "";

  @action.bound
  async login(form: any) {
    try {
      const { url, method } = authApi.login;
      // get call api method

      const result: any = await callApi(method, url, form);
      if (result && result.data) {
        const { id } = result.data;

        setLocalStorage("id", id);
        setLocalStorage("logged", "yes");

        message.info("登入成功");

        history.push("/dashboard");
      }
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }

  @action.bound
  async register(form: any) {
    try {
      const { url, method } = authApi.register;
      // get call api method
      const result: any = await callApi(method, url, form);

      if (result && result.data) {
        message.info("註冊成功！ 請先登入");
        history.push("login");
      }
    } catch (error: any) {
      message.error(error && error.message ? error.message : "Fail to login");
    }
  }

  @action.bound
  async logout() {
    try {
      removeAllLocalStorage();
      message.info("登出成功!");
      history.push("/login");
      return true;
    } catch (error: any) {
      removeAllLocalStorage();
      history.push("/login");
    }
  }
}

export const STORE_AUTH = "authStore";
