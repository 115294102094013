import React from 'react';

import { Table } from 'rsuite';
const { Cell } = Table;

export const EditCell = ({ rowData, dataKey, onChange, ...props }: any) => {
	const editing = rowData.status === 'EDIT';
	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''}>
			{editing ? (
				<input
					className="rs-input"
					defaultValue={rowData[dataKey]}
					onChange={(event) => {
						onChange && onChange(rowData.id, dataKey, event.target.value);
					}}
				/>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey]}</span>
			)}
		</Cell>
	);
};
