import React from 'react';

import { Table } from 'rsuite';
import { colors } from '../../config/style';

const { Cell } = Table;

const renderColor = (data: string | number, shouldFormatCell: boolean) => {
	if (shouldFormatCell) {
		data = typeof data === 'string' ? parseFloat(data.replace(/,/gi, '')) : data;
		if (data > 0) {
			return colors.success;
		} else if (data < 0) {
			return colors.danger;
		}
	}

	return 'interfit';
};

export const FormattedCell = ({ rowData, dataKey, onClick, ...props }: any) => {
	let { caldiff } = props;
	return (
		<Cell {...props} style={{ color: renderColor(rowData[dataKey], caldiff) }}>
			<span className="table-content-edit-span">{rowData[dataKey]}</span>
		</Cell>
	);
};
