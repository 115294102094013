import React from 'react';

// Style
import { colors, fonts } from '../../config/style';

interface TitleProps {
	title: string;
	color?: string;
	style?: object;
	size?: number;
}

export const Title = (props: TitleProps) => {
	let { title, color, style, size } = props;
	return (
		<h1
			style={{
				marginBottom: 0,
				color: color || colors.text,
				fontSize: size || fonts.titleFontSize || 24,
				fontFamily: fonts.titleFontFamily,
				fontWeight: 400,
				lineHeight: '28px',
				...style
			}}
		>
			{title}
		</h1>
	);
};
