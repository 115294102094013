import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
// Components
import {
  MainContainer,
  Subtitle,
  Text,
  BottomModal,
  Title,
} from "../../components";
import { Button, Select, DatePicker, message, InputNumber } from "antd";
import { useRouterStore, useMemberStore, useClassStore } from "../../stores";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const renderChild = ({
  history,
  member,
  filterClassList,
  branchList,
  currentBranch,
  setBranch,
  setSelectedDate,
  handleBooking,
  currentDate,
  isModalVisible,
  setIsModalVisible,
  currentClass,
  setAmountOfPeople,
  setCurrentClass,
  amountOfPeople,
  loading,
}: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "5%",
        width: "95%",
      }}
    >
      <Subtitle subtitle="預約訓練" />
      <Subtitle subtitle={"剩餘堂票:  " + member.currentTickets} />
      <Button
        style={{
          borderRadius: "5px",
          marginTop: "5%",
          backgroundColor: "#ffea76",
          height: "5vh",
          color: "#ffea76",
          fontSize: "1.5em",
          fontWeight: 400,
          width: "100%",
        }}
        onClick={() => history.push("purchase")}
      >
        <Text color="#4aa395" text="購買堂票" />
      </Button>

      <Select
        style={{ width: "100%", marginTop: "10%" }}
        value={currentBranch}
        onChange={(value) => {
          setBranch && setBranch(value);
        }}
      >
        {branchList &&
          branchList.length > 0 &&
          branchList.map((option: any) => {
            const { id, branchName } = option;
            return (
              <Option key={`input${id}`} value={id}>
                <Text
                  style={{ textAlign: "center", color: "grey" }}
                  text={branchName}
                />
              </Option>
            );
          })}
      </Select>

      <DatePicker
        defaultValue={moment()}
        disabledDate={(current: any) => {
          return (
            moment() >= moment(current).add(1, "day") ||
            moment().add(1, "month") <= current
          );
        }}
        style={{ width: "100%", marginTop: "5%", textAlign: "center" }}
        onChange={(date: any, dateString: any) => setSelectedDate(date)}
        disabled={loading}
        onFocus={(e: any) => (e.target.readOnly = true)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: "5%",
          alignItems: "baseline",
        }}
      >
        <Text style={{ flex: 1.5 }} text="Time" />
        <Text style={{ flex: 1 }} text="Class" />
        <Text style={{ flex: 1, textAlign: "center" }} text="Vacancy" />
        <Text style={{ flex: 0.5, textAlign: "center" }} text="Ticket" />
        <div style={{ flex: 0.5 }} />
      </div>

      {loading && (
        <LoadingOutlined
          style={{ marginTop: 20, fontSize: 24, color: "black" }}
        />
      )}

      {filterClassList &&
        filterClassList.length > 0 &&
        filterClassList.map((item: any) => {
          const {
            tickets = 1,
            startTime,
            endTime,
            className,
            vacancy,
            bookingHistory,
          } = item;
          const count =
            bookingHistory && bookingHistory.length > 0
              ? bookingHistory.filter((history: any) => {
                  let { courseDate, status } = history;
                  courseDate = moment(history.courseDate).format("DD-MM-YYYY");
                  return (
                    moment(courseDate, "DD-MM-YYYY").isSame(
                      moment(currentDate, "DD-MM-YYYY"),
                      "day"
                    ) && status !== "cancelled"
                  );
                })
              : [];

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "2%",
                alignItems: "baseline",
              }}
              key={startTime}
            >
              <Text style={{ flex: 1.5 }} text={`${startTime} - ${endTime}`} />
              <Text style={{ flex: 1 }} text={className} />
              <Text
                style={{ flex: 1, textAlign: "center" }}
                text={`${count.length}/${vacancy}`}
              />
              <Text style={{ flex: 0.5, textAlign: "center" }} text={tickets} />
              {member.currentTickets >= tickets ? (
                <div
                  style={{ flex: 0.5, textAlign: "right" }}
                  onClick={() => {
                    setIsModalVisible(true);
                    setCurrentClass(item);
                  }}
                >
                  <img
                    style={{ width: "55%" }}
                    alt="booking"
                    src="/assets/book1.png"
                  />
                </div>
              ) : (
                <div
                  style={{ flex: 0.5, textAlign: "right" }}
                  onClick={() => message.error("堂票不足，請先充值")}
                >
                  <img
                    style={{ width: "55%" }}
                    alt="booking"
                    src="/assets/book2.png"
                  />
                </div>
              )}
            </div>
          );
        })}

      <BottomModal open={isModalVisible} setOpen={setIsModalVisible}>
        {loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "40vh",
              padding: 20,
              justifyContent: "center",
            }}
          >
            <LoadingOutlined
              style={{ marginTop: 20, fontSize: 24, color: "black" }}
            />
          </div>
        )}

        {!loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "40vh",
              padding: 20,
              justifyContent: "center",
            }}
          >
            <Title title={"請確定預約人數"} color="black" />
            <Subtitle
              subtitle={currentClass ? currentClass.className : ""}
              color="black"
              style={{ marginTop: 20, marginBottom: 20 }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "black", marginRight: 20 }} text="人數: " />
              <InputNumber
                value={amountOfPeople || 1}
                min={1}
                onChange={(e: any) => setAmountOfPeople(e)}
              />
            </div>

            <Button
              style={{
                borderRadius: "5px",
                marginTop: 40,
                backgroundColor: "rgb(74, 163, 149)",
                height: "5vh",
                color: "rgb(74, 163, 149)",
                fontSize: "1.5em",
                fontWeight: 400,
                width: "70%",
              }}
              onClick={() => handleBooking()}
            >
              <Text color="white" text="確定" />
            </Button>
          </div>
        )}
      </BottomModal>
    </div>
  );
};

const BookingPage = () => {
  const { history } = useRouterStore();
  const { member, getMember } = useMemberStore();
  const { classList, getClassList, branchList, getBranchList, makeBooking } =
    useClassStore();

  const [currentBranch, setBranch] = useState(-1);
  const [filterClassList, setFilterClassList] = useState([]);
  const [currentDate, setCurrentDay] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentClass, setCurrentClass] = useState<any>(null);
  const [amountOfPeople, setAmountOfPeople] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (branchList.length !== 0) {
      const defaultBranch: any = branchList[0];
      const { id } = defaultBranch;
      setBranch(id);
    }
  }, [branchList]);

  useEffect(() => {
    if (classList.length > 0) {
      const day = moment(currentDate).format("dddd");

      const findMatched: any = classList.filter(
        (item: any) => item.week === day && item.branchId === currentBranch
      );
      setFilterClassList(findMatched || []);
      setLoading(false);
    }
  }, [currentBranch, classList, currentDate]);

  useEffect(() => {
    getMember();
    getClassList();
    getBranchList();
  }, [getMember, getBranchList, getClassList]);

  const setSelectedDate = (date: any, dateString: any) => {
    const day = moment(date).format("dddd");
    const findMatched: any = classList.filter((item: any) => item.week === day);
    setFilterClassList(findMatched || []);
    setCurrentDay(date);
  };

  const handleBooking = async () => {
    const { id, tickets, bookingHistory, vacancy } = currentClass;
    setLoading(true);
    const count =
      bookingHistory && bookingHistory.length > 0
        ? bookingHistory.filter((history: any) => {
            let { courseDate, status } = history;
            courseDate = moment(history.courseDate).format("DD-MM-YYYY");
            return (
              moment(courseDate, "DD-MM-YYYY").isSame(
                moment(currentDate, "DD-MM-YYYY"),
                "day"
              ) && status !== "cancelled"
            );
          })
        : [];

    if (!amountOfPeople || amountOfPeople === 0) {
      message.error("預約人數不可為 0");
      setIsModalVisible(false);
      setLoading(false);
      return false;
    }

    if (vacancy - count.length < amountOfPeople) {
      message.error("預約人數超出上限");
      setIsModalVisible(false);
      setLoading(false);
      return false;
    }

    if (tickets * amountOfPeople > member.currentTickets) {
      message.error("堂票不足，請先充值");
      setIsModalVisible(false);
      setLoading(false);
      return false;
    }

    await makeBooking({
      courseDate: currentDate,
      classSlotId: id,
      amountOfPeople,
    });
    setLoading(false);
  };

  const config = {
    history,
    member,
    classList,
    branchList,
    setBranch,
    currentBranch,
    filterClassList,
    setSelectedDate,
    handleBooking,
    currentDate,
    isModalVisible,
    setIsModalVisible,
    currentClass,
    setCurrentClass,
    setAmountOfPeople,
    amountOfPeople,
    loading,
  };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(BookingPage);
